import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaLinkedin, FaGithub, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactInfo = ({ item, index, inView }) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={inView ? { opacity: 1, x: 0 } : {}}
    transition={{ duration: 0.6, delay: index * 0.1 }}
    className="flex items-center space-x-4"
  >
    <div className={`text-gray-600 ${item.color}`}>
      {item.icon}
    </div>
    <div>
      <h4 className="font-medium text-gray-800">{item.label}</h4>
      {item.link ? (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          {item.value}
        </a>
      ) : (
        <p className="text-gray-600">{item.value}</p>
      )}
    </div>
  </motion.div>
);

const Contact = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const contactInfo = [
    {
      icon: <FaLinkedin className="text-3xl" />,
      label: 'LinkedIn',
      value: 'https://www.linkedin.com/in/harmanpreet-randhawa/',
      link: 'https://www.linkedin.com/in/harmanpreet-randhawa/',
      color: 'hover:text-blue-600'
    },
    {
      icon: <FaGithub className="text-3xl" />,
      label: 'GitHub',
      value: 'https://github.com/randhawah',
      link: 'https://github.com/randhawah',
      color: 'hover:text-gray-800'
    },
    {
      icon: <FaEnvelope className="text-3xl" />,
      label: 'Email',
      value: 'harman@harman-randhawa.ca',
      link: 'mailto:harman@harman-randhawa.ca',
      color: 'hover:text-red-600'
    },
    {
      icon: <FaMapMarkerAlt className="text-3xl" />,
      label: 'Location',
      value: 'Brampton, ON, Canada',
      color: 'hover:text-green-600'
    }
  ];

  return (
    <section id="contact" className="py-20 bg-gray-50">
      <div className="container mx-auto px-6">
        <div ref={ref} className="max-w-4xl mx-auto">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-center mb-16"
          >
            Let's <span className="text-blue-600">Connect</span>
          </motion.h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-semibold mb-6">Get in Touch</h3>
              <p className="text-gray-600 mb-8">
                I'm currently looking for new opportunities in data analysis. 
                Whether you have a question or just want to say hi, feel free to reach out!
              </p>
              <div className="space-y-6">
                {contactInfo.map((item, index) => (
                  <ContactInfo
                    key={index}
                    item={item}
                    index={index}
                    inView={inView}
                  />
                ))}
              </div>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-semibold mb-6">Quick Message</h3>
              <form className="space-y-4">
                <div>
                  <label className="block text-gray-700 mb-2">Name</label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Your Name"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Email</label>
                  <input
                    type="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="your@email.com"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Message</label>
                  <textarea
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
                    placeholder="Your message..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;