import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <section id="about" className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <div ref={ref}>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-center mb-16"
          >
            About <span className="text-blue-600">Me</span>
          </motion.h2>

          <div className="max-w-4xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <p className="text-lg text-gray-600 mb-6 leading-relaxed">
                Hi, I'm Harman Randhawa, a passionate Data Analyst with a keen eye for transforming complex data into actionable insights. I recently completed the comprehensive Data Analyst Bootcamp by Alex The Analyst, which has equipped me with practical skills in various data analysis tools and technologies.
              </p>
              
              <p className="text-lg text-gray-600 mb-6 leading-relaxed">
                My expertise includes working with Excel, SQL, Tableau, Power BI, Python, and Pandas. I have a strong foundation in data cleaning, statistical analysis, and data visualization, allowing me to present complex findings in clear, understandable ways.
              </p>

              <p className="text-lg text-gray-600 leading-relaxed">
                I'm currently seeking opportunities where I can apply my analytical skills and contribute to data-driven decision-making processes. I'm passionate about using data to solve real-world problems and help businesses make informed decisions.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;