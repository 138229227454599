import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import { SiTableau, SiMicrosoftexcel, SiPowerbi } from 'react-icons/si';

const ProjectCard = ({ project, index, inView }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={inView ? { opacity: 1, y: 0 } : {}}
    transition={{ duration: 0.6, delay: index * 0.2 }}
    className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
  >
    <div className="relative h-48 overflow-hidden">
      <img
        src={project.image}
        alt={project.title}
        className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-300"
      />
    </div>
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-3">{project.title}</h3>
      <p className="text-gray-600 mb-4">{project.description}</p>
      <div className="flex flex-wrap gap-2 mb-4">
        {project.tools.map((tool, toolIndex) => (
          <span
            key={toolIndex}
            className="bg-blue-100 text-blue-800 text-sm px-3 py-1 rounded-full"
          >
            {tool}
          </span>
        ))}
      </div>
      <div className="flex space-x-4">
        <a
          href={project.github}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
        >
          <FaGithub className="mr-2" /> Code
        </a>
        {project.tableau ? (
          <a
            href={project.tableau}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
          >
            <SiTableau className="mr-2" /> Tableau Public
          </a>
        ) : (
          <a
            href={project.live}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
          >
            <FaExternalLinkAlt className="mr-2" /> Live Demo
          </a>
        )}
      </div>
    </div>
  </motion.div>
);

const Projects = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const projects = [
    {
      title: 'Data Cleaning & Analysis',
      description: 'Comprehensive data cleaning project using SQL, improving data quality by 95% and establishing standardized cleaning procedures.',
      image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&w=800&q=80',
      tools: ['SQL', 'Data Cleaning', 'PostgreSQL'],
      github: '#',
      live: '#'
    },
    {
      title: 'Sales Analytics Dashboard',
      description: 'Interactive Power BI dashboard providing real-time insights into sales performance, helping increase revenue by 23%.',
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=800&q=80',
      tools: ['Tableau Public', 'DAX', 'Data Modeling'],
      github: '#',
      tableau: '#'
    },
    {
      title: 'Market Analysis',
      description: 'Python-based market analysis tool using Pandas and Matplotlib, revealing key market trends and opportunities.',
      image: 'https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?auto=format&fit=crop&w=800&q=80',
      tools: ['Python', 'Pandas', 'Matplotlib'],
      github: '#',
      live: '#'
    },
    {
      title: 'Financial Report Automation',
      description: 'Automated financial reporting system using Excel VBA, reducing manual work by 75% and improving accuracy.',
      image: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?auto=format&fit=crop&w=800&q=80',
      tools: ['Excel', 'VBA', 'Automation'],
      github: '#',
      live: '#'
    }
  ];

  return (
    <section id="projects" className="py-20 bg-gray-50">
      <div className="container mx-auto px-6">
        <div ref={ref}>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-center mb-16"
          >
            Featured <span className="text-blue-600">Projects</span>
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {projects.map((project, index) => (
              <ProjectCard
                key={index}
                project={project}
                index={index}
                inView={inView}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;