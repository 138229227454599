import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaDatabase, FaPython, FaChartBar, FaTools } from 'react-icons/fa';
import { SiTableau, SiMicrosoftexcel, SiPowerbi } from 'react-icons/si';

const SkillCategory = ({ category, index, inView }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={inView ? { opacity: 1, y: 0 } : {}}
    transition={{ duration: 0.6, delay: index * 0.2 }}
    className="bg-gray-50 p-6 rounded-xl shadow-lg"
  >
    <h3 className="text-xl font-semibold text-blue-600 mb-6 text-center">
      {category.category}
    </h3>
    <div className="space-y-6">
      {category.items.map((skill, skillIndex) => (
        <div key={skillIndex} className="flex items-center space-x-4">
          <div className="text-2xl text-blue-600">{skill.icon}</div>
          <div>
            <h4 className="font-medium">{skill.name}</h4>
            <div className="mt-1 bg-gray-200 h-2 rounded-full">
              <motion.div
                initial={{ width: 0 }}
                animate={inView ? { width: skill.level === 'Advanced' ? '90%' : '75%' } : {}}
                transition={{ duration: 1, delay: 0.5 }}
                className={`h-full rounded-full ${
                  skill.level === 'Advanced' ? 'bg-blue-600' : 'bg-blue-400'
                }`}
              />
            </div>
            <p className="text-sm text-gray-600 mt-1">{skill.level}</p>
          </div>
        </div>
      ))}
    </div>
  </motion.div>
);

const Skills = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const skills = [
    {
      category: 'Data Analysis',
      items: [
        { name: 'SQL', icon: <FaDatabase />, level: 'Intermediate' },
        { name: 'Python & Pandas', icon: <FaPython />, level: 'Intermediate' },
        { name: 'Excel', icon: <SiMicrosoftexcel />, level: 'Advanced' }
      ]
    },
    {
      category: 'Visualization',
      items: [
        { name: 'Tableau', icon: <SiTableau />, level: 'Intermediate' },
        { name: 'Power BI', icon: <SiPowerbi />, level: 'Intermediate' },
        { name: 'Data Visualization', icon: <FaChartBar />, level: 'Advanced' }
      ]
    },
    {
      category: 'Tools & Methods',
      items: [
        { name: 'Data Cleaning', icon: <FaTools />, level: 'Advanced' },
        { name: 'Statistical Analysis', icon: <FaChartBar />, level: 'Intermediate' },
        { name: 'ETL Processes', icon: <FaDatabase />, level: 'Intermediate' }
      ]
    }
  ];

  return (
    <section id="skills" className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <div ref={ref}>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-center mb-16"
          >
            Technical <span className="text-blue-600">Skills</span>
          </motion.h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {skills.map((category, index) => (
              <SkillCategory
                key={index}
                category={category}
                index={index}
                inView={inView}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;